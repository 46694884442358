import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";

const QrScanner = ({ setQrData }) => {
  const [data, setData] = useState("");
  useEffect(() => {
    setQrData(data);
  }, [data]);

  return (
    <QrReader
      style={{ width: "100%" }}
      containerstyle={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 5,
      }}
      videoContainerStyle={{
        paddingTop: "82vh",
      }}
      onResult={(result) => {
        console.log(result);
        if (result && result?.text !== "'Twas brillig") setData(result.text);
      }}
      videoStyle={{
        objectFit: "cover",
      }}
      constraints={{ facingMode: "environment" }}
      //   initialStream={{
      //     facingMode: "environment",
      //   }}
      //   videoContainerStyle={{ position: "absolute", width: "100vw", zIndex: 5 }}
    />
  );
};

export default QrScanner;
