import React, { useEffect, useState } from "react";
import "./App.scss";
import Home from "./Home";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

function App() {
  const [allowed, setAllowed] = useState(false);
  const [app, setApp] = useState(null);
  const [messagingState, setMessagingState] = useState(null);
  useEffect(() => {
    // Initialize Firebase
    console.log(process.env.REACT_APP_FIREBASE_API_KEY);
    if (allowed) setApp(initializeApp(firebaseConfig));
  }, [allowed]);
  useEffect(() => {
    // Initialize Firebase Cloud Messaging and get a reference to the service
    if (app) setMessagingState(getMessaging(app));
  }, [app]);
  useEffect(() => {
    // Initialize Firebase Cloud Messaging and get a reference to the service
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    if (isSupported() && messagingState) {
      getToken(messagingState, { vapidKey: process.env.REACT_APP_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            // alert(`Firebase token: ${currentToken}`);
            console.log(`Firebase token: ${currentToken}`);
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    }
  }, [messagingState]);

  useEffect(() => {
    if (isSupported() && "Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          setAllowed(true);
        }
      });
    }
  }, []);

  return <Home />;
}

export default App;
