import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { BiCurrentLocation, BiNotification } from "react-icons/bi";
import { BsFillCameraFill } from "react-icons/bs";
import InstallPWA from "./components/InstallPWA";
import QrScanner from "./components/QrScanner";
const Home = () => {
  const camerRef = useRef();
  const [qrData, setQrData] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [installBtn, setInstallBtn] = useState(true);
  const [coords, setCoords] = useState({
    lat: "",
    lng: "",
  });
  useEffect(() => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setInstallBtn(false);
    }
  }, []);

  var geoSuccess = function (position) {
    setCoords({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
  };
  var geoError = function (error) {
    switch (error.code) {
      case error.TIMEOUT:
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (coords.lat) {
      alert(`Lattitude: ${coords.lat}, Longitude: ${coords.lng}`);
    }
  }, [coords]);
  useEffect(() => {
    if (qrData) {
      console.log(qrData);
      setShowCamera(false);
    }
  }, [qrData]);
  const checkQrUrl = () => {
    let url;
    try {
      url = new URL(qrData);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };
  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">Sun water soil</Navbar.Brand>
          {showCamera ? (
            <Button
              variant="outline-danger px-4"
              className="rounded-pill"
              onClick={() => setShowCamera(false)}
            >
              Cancel
            </Button>
          ) : installBtn ? (
            <Nav className="ms-auto">
              <Nav.Link href="#">
                <InstallPWA />
              </Nav.Link>
            </Nav>
          ) : (
            ""
          )}
        </Container>
      </Navbar>
      <Container className="py-4 bg-light vh-91 position-relative overflow-hidden">
        {showCamera ? (
          <QrScanner setQrData={setQrData} />
        ) : (
          <Row>
            <Col xs={6} md={4}>
              {/* <input
              accept="image/*"
              id="icon-button-file"
              type="file"
              capture="environment"
              ref={camerRef}
              hidden
            /> */}
              <Card
                className="d-flex align-items-center justify-content-center action-card mb-3 cursor-pointer pt-4"
                onClick={() => {
                  // camerRef.current?.click();
                  setShowCamera(true);
                }}
              >
                <BsFillCameraFill size={"10vw"} className="text-primary" />
                <h3 className="action-text">Camera</h3>
              </Card>
            </Col>
            <Col xs={6} md={4}>
              <Card
                className="d-flex align-items-center justify-content-center action-card mb-3 cursor-pointer pt-4"
                onClick={() => {}}
              >
                <BiNotification size={"10vw"} className="text-warning" />
                <h3 className="action-text">Notification</h3>
              </Card>
            </Col>
            <Col xs={6} md={4}>
              <Card
                className="d-flex align-items-center justify-content-center action-card mb-3 cursor-pointer pt-4"
                onClick={() => {
                  navigator.geolocation.getCurrentPosition(
                    geoSuccess,
                    geoError
                  );
                }}
              >
                <BiCurrentLocation size={"10vw"} className="text-success" />
                <h3 className="action-text">Location</h3>
              </Card>
            </Col>
            {qrData ? (
              <Col xs={12}>
                <Card className="d-flex align-items-center justify-content-center mb-3 cursor-pointer py-3">
                  {checkQrUrl() ? (
                    <a
                      href={qrData}
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Qr link
                    </a>
                  ) : (
                    <p>{qrData}</p>
                  )}
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
        )}
      </Container>
    </>
  );
};

export default Home;
